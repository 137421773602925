import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { SmallerContainer, ThemeContext } from 'components/common'
import { MagicalButton, Wrapper, Flex } from './styles'

export const Intro = () => {
	const { theme } = useContext(ThemeContext)
	return (
		<Wrapper theme={theme} as={SmallerContainer}>
			<h1>I'm Gaurav Abbhi</h1>
			<p>
				Also known as Karan, a self-taught Full Stack JavaScript Developer and UI/UX Designer.
			</p>
			<Flex>
				<MagicalButton
					href="https://drive.google.com/open?id=1BAKiaa0feWnm_LmfgkDYlAS6SMcBk8nriNnf0j9mtqg"
					rel="noopener noreferrer"
					target="_blank"
				>
					View resume
				</MagicalButton>
				<MagicalButton as={Link} to="/about">
					About Me
				</MagicalButton>
			</Flex>
		</Wrapper>
	)
}
